.footer-info-section{
    background-color: var(--primary-color);
    padding: 8.8rem 0rem;
    .footer-info-section--dashed{
        border-right: initial;
        border-bottom: 1px dashed var(--white-color);
    }
    p,a,ul{
        color: var(--white-color);
    }
    ul{
        list-style: none;
        li{
            line-height: 2.8rem;
            display: grid;
            grid-template-columns: 10% 90%;
            align-items: center;
            row-gap: 2.8rem;
            column-gap: 2.8rem;
        }
    }
    .footer-info-section--grid-6{
        line-height: 2.8rem;
        display: grid;
        grid-template-columns: 10% 90%;
        align-items: center;
        row-gap: 2.8rem;
        column-gap: 1.8rem;
        padding: 2.8rem;
    }
    .footer-info-section--padding{
        padding: 2.8rem;
    }    
    .icon-envelope{
        color: var(--primary-color);
        background-color: var(--third-color);
        padding: 1.0rem 1.2rem;
        border-radius: 50%;
        width: 4.5rem;
        height: 4.5rem;          
        text-align: center;      
    } 
    .icon-envelope:before{
        vertical-align: middle;
    }          
    .icon-house{
        color: var(--primary-color);
        background-color: var(--third-color);
        padding: 1.2rem 1.2rem;
        border-radius: 50%;
        width: 4.5rem;
        height: 4.5rem;
        text-align: center;        
    } 
    .icon-house:before{
        vertical-align: middle;
    }         
    .icon-mobile{
        color: var(--primary-color);
        background-color: var(--third-color);
        padding: 1.0rem 1.2rem;
        border-radius: 50%;
        width: 4.5rem;
        height: 4.5rem;
        text-align: center;
    }
    .icon-mobile:before{
        vertical-align: middle;
    }    
    .footer-info-section__copy{
        border-top: 1px dashed var(--white-color);
        text-align: center;
        padding-top: 8.8rem;
        margin-top: 2.8rem;
    }
}

// Small devices 
@media (min-width: 576px) {

}

// Medium devices 
@media (min-width: 768px) {

}

// Large devices
@media (min-width: 992px) {
    .footer-info-section{
        .footer-info-section--dashed{
            border-right: 1px dashed var(--white-color);
            border-bottom: none;
        }
    }    
}

// Extra large devices
@media (min-width: 1200px) {

}

// Extra Extra large devices 
@media (min-width: 1400px) {

}